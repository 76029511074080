.pop-toggle-button{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 40px;
}

.pop-toggle-button .heading-4{
    cursor: pointer;
    padding-bottom: 13px;
    border-bottom: 2px solid transparent;
}

.pop-toggle-button .heading-4.active{
    border-color: var(--darkblue);
}

.adminResult{
    visibility: hidden;
    opacity: 0;
    transition: 0.4s ease-in-out;
}

.adminResult.open{
    visibility: visible;
    opacity: 1;
    transition: 0.4s ease-in-out;
}

.adminResult .dashheader{
    padding-bottom: 0;
}

.adminResult .result_partition_wrapper{
    display: none;
}

.adminResult .result_partition_wrapper.active{
    display: flex;
}

.datatinfo-popup{
    width: 100%;
    border: 1px solid var(--vlight-grey);
    border-radius: 5px;
}

.datatinfo-popup .rounds-count{
    padding: 20px;
}

.datainfo-body .result_table{
    border: 1px solid var(--vlight-grey);
    border-radius: 5px;
    overflow: hidden;
    width: 100%;
    border-spacing: 0;
}

.datainfo-body table thead {
    background: var(--black);
}

.datainfo-body table th {
    font-size: 14px;
    color: var(--white);
    text-align: left;
    padding: 15px;
    font-family: 'inter-regular';
    line-height: 120%;
    border-style: none;
    min-width: 110px;
    font-weight: 400;
}

.adminResult .popup{
    max-width: calc(100% - 250px);
    width: 100vw;
    overflow: auto;
}

.datainfo-body{
    padding: 20px;
    border-top: 1px solid var(--vlight-grey);
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
}

.datainfo-body table td {
    font-size: 14px;
    color: var(--black);
    text-align: left;
    padding: 15px;
    font-family: 'inter-regular';
    line-height: 120%;
    border-style: none;
    min-width: 110px;
    font-weight: 400;
}