.admindashboard-wrapper{
    display: block;
    width: calc(100% - 240px);
    height: calc(100vh - 62px);
    margin-left: auto;
    position: relative;
    background: var(--cream-white);
    padding: 10px 20px;
}

.admin-dash-top .datasetnav{
    border: 1px solid var(--vlight-grey);
}

.admin-dash-top .heading-4{
    font-family: 'inter-bold';
}

.admin-data-wrapper{
    padding: 20px;
    background: var(--white);
    border: 1px solid var(--vlight-grey);
    border-top: 0;
}

.admin-boxes-wrapper{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.admin-boxes{
    position: relative;
    padding: 5px;
    background: var(--white);
    border: 1px solid var(--vlight-grey);
    border-radius: 5px;
    max-width: 25%;
    width: 100%;
}

.box-header{
    background: var(--darkblue);
    padding: 12px 15px;
    border-radius: 5px;
}

.box-header .text-3{
    color: var(--white);
    font-size: 14px;
}

.box-mid{
    background: rgb(34 119 236 / 15%);
    padding: 15px;
    border-radius: 5px;
}

.box-mid .heading-5{
    font-size: 20px;
    font-family: 'inter-semibold';
    line-height: 24px;
    color: var(--blue);
    display: flex;
    align-items: center;
    gap: 5px;
}

.box-bottom{
    padding: 15px;
}

.box-bottom table{
    width: 100%;
}

.box-bottom table tr{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.box-bottom table td{
    font-size: 14px;
    line-height: 18px;
}

.text-left{
    text-align: left;
}

.text-right{
    text-align: right;
}

.red-data{
    background: rgb(255 87 35 / 15%);;
}

.red-data .heading-5{
    color: #FF5723;
}

.yellow-data{
    background: rgb(252 196 67 / 15%);;
}

.yellow-data .heading-5{
    color: var(--yelllow)
}

.green-data{
    background: rgb(68 183 94 / 15%);;
}

.green-data .heading-5{
    color: var(--green)
}

.filter-tab{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.filter-tab .icon-buttons{
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
    border: 1px solid var(--black);
    color: var(--black);
    min-width: max-content;
    padding: 8px 10px;
    border-radius: 5px;
    font-size: 12px;
    border: 1px solid var(--black);
    color: var(--black);
    min-width: max-content;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    background: var(--white);
    font-size: 14px;
}

.filter-btn-wrapper{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    position: relative;
    width: 100%;
}

.availableFilters{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    width: 100%;
}

.filterspan{
    border: 1px solid var(--light-grey);
    border-radius: 5px;
    padding: 10px;
    background: var(--white);
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.filterspan svg{
    cursor: pointer;
}