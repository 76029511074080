.scorePopup{
    visibility: hidden;
    opacity: 0;
    z-index: -99;
    transition: 0.4s ease-in-out;
}

.scorePopup.open{
    visibility: visible;
    opacity: 1;
    z-index: 3500;
    transition: 0.4s ease-in-out;
}

.scorePopup .popup{
    max-width: 420px;
    width: 100vw;
}

.scorePopup .dashheader{
    max-width: 100%;
    padding: 0;
    padding-bottom: 15px;
}

.scorePopup .popup-body{
    margin-top: 24px;
}

.scorePopup .popup-footer{
    margin-top: 34px;
}

.rc-slider-track{
    height: 10px !important;
    background: #2277EC !important;
}

.rc-slider-rail{
    height: 10px !important;
}

.rc-slider-step{
    height: 10px !important;
}

.rc-slider{
    padding: 0 !important;
    height: 10px !important;
}

.rc-slider-handle {
    position: absolute;
    width: 18px !important;
    height: 18px !important;
    margin-top: -4px;
    background-color: #fff !important;
    border: solid 2px #2277EC !important;
    border-radius: 50% !important;
    cursor: pointer;
    cursor: grab;
    opacity: 1 !important;
    touch-action: pan-x;
}