.popup_start{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3500;
    backdrop-filter: blur(1px);
    background: rgba(0,0,0,0.6);
    display: flex;
    overflow: hidden;
    transition: 0.4s ease-in-out;
}

.popup_wrapper{
    margin: auto;
    padding: 20px;
}

.popup {
    max-width: 600px;
    max-height: calc(100vh - 40px);
    background: var(--white);
    margin: auto;
    padding: 20px;
    overflow: auto;
    border-radius: 10px;
}

.welcome_popup .text-4{
    line-height: 180%;
}

.welcome_popup .text-3{
    line-height: 150%;
}

.welcome_popup .forget-link{
    font-size: 16px;
}