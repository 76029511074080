.user-details-wrapper{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    width: calc(100% - 240px);
    height: calc(100vh - 62px);
    margin-left: auto;
    position: relative;
    background: var(--cream-white);
    padding: 20px;
    overflow: hidden;
}

.user-details-wrapper .admindashboard-wrapper{
    width: 100%;
    padding: 0;
    height: calc(100vh - 100px);
}

.user-details-left{
    max-width: 352px;
    width: 100%;
}

.user-details-all{
    width: 100%;
    background: var(--white);
    border: 1px solid var(--vlight-grey);
    border-radius: 5px;
}

.name-section{
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.first-letters{
    min-width: 74px;
    height: 74px;
    border-radius: 50%;
    background: var(--darkblue);
    color: var(--white);
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'inter-semibold';
    font-size: 28px;
    line-height: 34px;
}

.full-name .text-4{
    color: var(--black);
    font-family: 'inter-semibold';
}

.full-name .text-3{
    color: var(--blue);
    padding: 4px 8px;
    background: rgb(34 119 236 / 15%);
    border-radius: 5px;
}

.attempts-section{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 20px;
    gap: 30px;
    border-top: 1px solid var(--vlight-grey);
}

.attempts-details{
    text-align: center;
}

.attempts-details .text-4{
    font-size: 22px;
    line-height: 27px;
    font-family: 'inter-semibold';
}

.attempts-details .text-3{
    font-size: 12px;
    font-family: 'inter-regular';
    color: var(--darkblue);
}

.user-details-time .admin-boxes{
    max-width: 100%;
}

.clickable{
    color: var(--blue);
}

.user-details-clicks .admin-boxes{
    max-width: 100%;
}

.user-details-right{
    max-width: calc(100% - 352px);
    width: 100%;
}