.result_page_wrapper{
    display: block;
    width: calc(100% - 240px);
    height: calc(100vh - 61px);
    margin-left: auto;
    position: relative;
    background: var(--cream-white);
    padding: 10px;
    overflow: hidden;
}

.result_page_wrapper .result_table{
    width: 100%;
}

.result_page_wrapper .datasettable{
    background: var(--white);
    max-width: 100%;
    width: 100%;
    margin: auto;
    margin-top: 10px;
    max-height: calc(100vh - 200px);
    overflow: auto;
}

.result_page_wrapper .datasettable thead{
    position: sticky;
    top: 0;
}

.table_btn_wrapper{
    text-align: center !important;
    padding: 10px 15px;
}

.table_btn{
    padding: 6px 15px;
    color: var(--blue);
    border: 1px solid var(--blue);
    background: var(--white);
    font-size: 14px;
    border-radius: 5px;
    transition: 0.4s ease-in-out;
}

.table_btn:hover{
    background: var(--blue);
    color: var(--white);
    transition: 0.4s ease-in-out;
}

.no-answer{
    position: relative;
    padding: 20px;
    background: var(--white);
    border: 1px solid var(--vlight-grey);
    border-radius: 5px;
    height: 100%;
    max-height: calc(100% - 70px);
    text-align: center;
}

.no-answer .heading-6{
    color: var(--lightblack);
    font-size: 14px;
}