.sidemenu{
    position: fixed;
    max-width: 240px;
    width: 100%;
    height: 100vh;
    background: var(--darkblue);
}

.dashboard_logo{
    padding: 20px;
}

.dashmenu_list{
    padding: 0;
    list-style: none;
}

.dashlink{
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    padding: 12px 20px;
    gap: 10px;
    color: var(--white);
    text-decoration: none;
    font-size: 15px;
    transition: 0.4s ease-in-out;
}

.dashmenu .dashlink.active{
    background: var(--blue);
    transition: 0.4s ease-in-out;
}

.dashmenu:hover .dashlink{
    background: var(--blue);
    transition: 0.4s ease-in-out;
}

.notification {
    min-width: 20px;
    min-height: 20px;
    color: var(--darkblue);
    background: var(--white);
    border-radius: 5px;
    line-height: 130%;
    font-family: 'inter-regular';
    display: flex;
    align-items: center;
    justify-content: center;
}
.opacityHalf{
    opacity: 50%;
}