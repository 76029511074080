.userlist-wrapper .datasetnav .drop_selector select{
    min-width: 76px;
    font-size: 14px;
}

.datatable-admin{
    min-height: max-content;
    max-height: calc(100% - 70px);
}

.datatable-admin .result_table{
    width: 100%;
    border: 1px solid var(--vlight-grey);
    border-radius: 5px;
    overflow: hidden;
}

.datatable-admin .result_table .table_btn_wrapper{
    padding: 5px 15px !important;
}