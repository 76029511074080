.login-wrapper{
    background: var(--cream-white);
}

.login-left{
    background: var(--darkblue);
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.login-right{
    min-width: calc(100% - 50%);
    min-height: 100vh;
}

.login-header{
    padding: 40px;
    position: sticky;
    display: block;
    min-width: calc(100% - 50%);
    z-index: 9;
}

.form-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100% - 104px);
}

.signup-form{
    max-width: 400px;
    width: 100%;
    margin: auto;
}

.login-form label{
    display: block;
    font-size: 14px;
}

.form-input{
    display: block;
    background: var(--white);
    max-width: 400px;
    width: 100%;
    border: 1px solid var(--vlight-grey);
    padding: 15px;
    border-radius: 5px;
    font-size: 16px;
}

.form-input::placeholder{
    color: var(--grey);
    font-size: 16px;
}

.form-options-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.form-options-wrapper .form-options{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.forget-link{
    text-decoration: none;
    font-size: 14px;
}

.blue-Submit{
    width: 100%;
    display: block;
    background: var(--blue);
    border: 1px solid var(--blue);
    color: var(--white);
    box-shadow: none;
    outline: none;
    font-size: 16px;
    padding: 15px;
    border-radius: 5px;
    cursor: pointer;
}

.google-signin{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--white);
    color: var(--black);
    border: 1px solid var(--grey);
    box-shadow: none;
    outline: none;
    font-size: 16px;
    padding: 15px;
    border-radius: 5px;
    gap: 10px;
    cursor: pointer;
}

.signin-form{
    max-width: 400px;
    width: 100%;
    margin: auto;
}

.create-account{
    position: relative;
    display: block;
}