.result_popup .popup{
    max-width: calc(100% - 250px);
    width: 100vw;
    min-height: max-content;
    background: var(--white);
    margin: auto;
    padding: 20px;
    border-radius: 10px;
}

.result_popup .dashheader{
    max-width: 100%;
    padding: 0px;
    padding-bottom: 15px;
}

button.close{
    border: 0;
    background: transparent;
    line-height: 0;
}

.result_header{
    padding: 15px;
    background: var(--black);
}

.result_header .heading-4{
    color: var(--white);
    line-height: 150%;
    font-size: 16px;
}

.result_wrapper{
    border: 1px solid var(--vlight-grey);
    border-radius: 10px;
    overflow: hidden;
    width: -webkit-fill-available;
}

.result_score_point{
    padding: 15px;
    padding-top: 0;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
}

.result_score_point .text-3{
    max-width: calc(100% - 100px);
}

span.score_point{
    font-size: 13px;
    line-height: 150%;
    padding: 2px 5px;
    background: var(--vlight-grey);
    border-radius: 5px;
}

.progress_bar{
    display: block;
    width: 100%;
    border: 1px solid var(--vlight-grey);
    background: var(--vlight-grey);
    border-radius: 5px;
    overflow: hidden;
}

.progress_bar .progress{
    min-height: 5px;
    width: 20%;
    background: var(--blue);
    border-radius: 5px;
}

.answer_wrapper .result_header{
    background: var(--green);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.answer_wrapper .result_header .heading-4{
    max-width: calc(100% - 100px);
}

.answer_wrapper .result_header span.score_point{
    background: var(--white);
    color: var(--black);
}

.answer_wrapper .score_point{
    color: var(--white);
}

.answer_wrapper .result_score_point{
    justify-content: flex-start;
    gap: 10px;
}

.result_partition_wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-self: flex-start;
    gap: 20px;
    max-height: calc(100vh - 150px);
    overflow: auto;
}

.result_partition_left{
    max-width: calc(100% - 420px);
}

.result_partition_right{
    max-width: 400px;
    width: 100%;
}

.score_board{
    border: 1px solid var(--vlight-grey);
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
}

.score_board_progress{
    padding: 20px 15px;
}

.score_board_details{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.score_board_details .heading-3{
    line-height: 0.9;
    font-size: 32px;
    font-family: 'inter-bold';
}

.score_board_progress .progress_bar{
    min-height: 50px;
}

.score_board_progress .progress_bar .progress{
    min-height: inherit;
    width: 26%;
    border-radius: 0;
}


.newResultWrapper{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    width: 100%;
}

.decission-mid{
    width: 100%;
}

.decission-mid table{
    width: 100%;
    border: 1px solid var(--vlight-grey);
    border-radius: 5px;
    overflow: hidden;
    border-spacing: 0;
}

.decission-mid table thead {
    background: var(--darkblue);
}

.decission-mid table th {
    font-size: 14px;
    color: var(--white);
    text-align: left;
    padding: 15px;
    font-family: 'inter-regular';
    line-height: 120%;
    border-style: none;
    min-width: 110px;
    font-weight: 400;
}

.decission-mid table td {
    font-size: 14px;
    color: var(--black);
    text-align: left;
    padding: 15px;
    font-family: 'inter-regular';
    line-height: 120%;
    border-style: none;
    min-width: 110px;
    font-weight: 400;
}

.darkbluebold{
    color: var(--darkblue);
    font-family: 'inter-bold';
}

.percent-score-box{
    border-top: 1px solid var(--vlight-grey);
    padding: 20px 15px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 15px;
}

.percent-score-box .icon-percent{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.box-percent{
    background: #2277EC;
    color: var(--white);
    padding: 10px 15px;
    border-radius: 5px;
}

.box-percent.yellow-box{
    background: #FCC443;
}

.time-taken-box {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

.time-taken-box .time{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 5px;
    flex-wrap: wrap;
}

.time-taken-box .time span{
    width: 48px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FBEBEB;
    border-radius: 5px;
    color: #D63736;
    font-size: 16px;
}

.time-taken-box .time p{
    text-align: center;
    display: block;
    width: 100%;
}

.colon{
    position: relative;
    top: -12px;
}