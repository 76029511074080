    .start_round_wrapper{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 40px;
    }

    .start_round_popup .popup {
        max-width: 420px;
        width: 100%;
        max-height: 238px;
        height: 100%;
    }

    .start_round_popup .popup .dashheader {
        max-width: 100%;
        padding: 15px 0;
    }

    .start_round_popup .popup .dashheader .heading-4 {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
    }

    .start_round_buttton {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
        width: 100%;
    }

    .start_round_buttton button {
        min-width: 70px;
    }

    .table_btn_fill {
        padding: 6px 15px;
        border: 1px solid var(--blue);
        background: var(--blue);
        color: var(--white);
        font-size: 14px;
        border-radius: 5px;
        transition: 0.4s ease-in-out;
    }