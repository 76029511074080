.dashheader{
    max-width: calc(100% - 240px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    top: 0;
    right: 0;
    padding: 15px 20px;
    border-bottom: 1px solid var(--vlight-grey);
    margin-left: auto;
}

.timer{
    font-size: 16px;
    padding: 5px;
    border-radius: 4px;
    background: rgba(214, 55, 54, 0.1);
}

.timer i{
    font-style: normal;
    color: var(--red);
}

.timerWrapper{
    gap: 10px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.timerWrapper .table_btn_fill{
    background: var(--darkblue);
    border-color: var(--darkblue);
}