.decision-wrapper{
    display: block;
    width: calc(100% - 240px);
    height: calc(100vh - 146px);
    margin-left: auto;
    position: relative;
    background: var(--cream-white);
    padding: 10px;
    overflow: hidden;
}

.decision-wrapper.with_question{
    height: calc(100vh - 62px);
}

.decision-header{
    background: var(--white);
    padding: 20px;
    border: 1px solid var(--vlight-grey);
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.decision-header .heading-4{
    line-height: 150%;
}

.decision-questions{
    width: 100%;
    height: calc(100% - 162px);
    background: transparent;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 10px;
}

.decision-questions .decision-question-left{
    padding: 20px;
    /* max-width: calc(100% - 310px); */
    width: 100%;
    max-width: 100%;
    background: var(--white);
    border: 1px solid var(--vlight-grey);
    border-radius: 5px;
    /* height: 100%; */
    overflow: auto;
}

.decision-questions .decision_question_right{
    max-width: 300px;
    height: 100%;
    overflow: auto;
}

.question_list{
    display: block;
    list-style: none;
    padding-left: 20px;
}

.question_list li{
    display: block;
    position: relative;
}

.question_list li input{
    display: none;
}

.checkmark{
  transition: 0.4s;
}

/* .question_list li:hover input ~ .checkmark {
  background-color: var(--green);
  color: var(--white);
  transition: 0.4s;
} */

/* When the checkbox is checked, add a blue background */
.question_list li input:checked ~ .checkmark {
  background-color: #2196F3;
  background-color: var(--green);
  color: var(--white);
  transition: 0.4s;
}

.question_list li label{
    font-size: 14px;
    color: var(--darkblue);
    line-height: 150%;
    padding: 20px;
    border: 1px solid var(--vlight-grey);
    border-radius: 5px;
    background: var(--white);
    position: relative;
    display: block;
    cursor: pointer;
    
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

.question_list li::before{
    content: "";
    position: absolute;
    left: -25px;
    top: 0;
    min-height: 100%;
    min-width: 20px;
    background: url(https://mastersunion.s3.ap-south-1.amazonaws.com/assets/imgV2/PM_simulator_DotsNine.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.decision_question_right .position{
    padding: 5px;
    border: 1px solid var(--vlight-grey);
    background: var(--white);
    border-radius: 5px;
}

.decision_question_right .position_heading .text-3{
    display: block;
    padding: 10px 20px;
    background-color: var(--darkblue);
    border-radius: 5px;
    color: var(--white);
    text-align: center;
    font-size: 14px;
}

.decision_question_right .position_answer{
    min-height: 125px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed var(--vlight-grey);
    background: var(--cream-white);
    text-align: left;
    border-radius: 5px;
    padding: 20px;
}

.decision_question_right .position_answer .text-3{
    font-size: 14px;
    line-height: 150%;
    color: var(--grey);
}

.decision_question_right .position_answer.has_answer{
    background-color: var(--green);
}

.decision_question_right .position_answer.has_answer .text-3{
    color: var(--white);
}

.decision-buttons{
    min-height: 80px;
    display: block;
    width: 100%;
    background: var(--white);
    border: 1px solid var(--vlight-grey);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 20px;
}

.decision-buttons.multiple-btn{
    justify-content: space-between;
}

.decision-buttons .blue-Submit{
    display: inline-block;
    max-width: 192px;
    width: 100%;
    padding: 10px;
}


.decision_next_wrapper{
    display: block;
    width: calc(100% - 240px);
    height: calc(100vh - 62px);
    margin-left: auto;
    position: relative;
    background: var(--cream-white);
    padding: 10px;
}

.decision_imput{
    min-width: 100%;
    display: block;
    line-height: 0;
}

.decision_imput textarea{
    padding: 20px;
    width: 100%;
    border: 1px solid var(--vlight-grey);
    border-radius: 5px;
    resize: none;
    transition: 0.4s ease-in-out;
    outline: none;
}

.decision_imput textarea:focus, .decision_imput textarea:active{
    transition: 0.4s ease-in-out;
}

.decision_main_question{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
}

.decision_main_question .heading-4{
    line-height: 150%;
}

/* .decision-wrapper.with_question .decision-question-left{
    max-width: calc(100% - 310px);
} */

/* .decision-wrapper .adjust-design:nth-child(2){
    max-width: calc(100% - 310px);
    width: 100%;
} */

.decision-wrapper .adjust-design:nth-child(2) {
    max-width: calc(100% - 300px);
    width: 100%;
    float: left;
    max-height: calc(100vh - 225px);
    height: 100%;
}

.decision-wrapper .adjust-design:nth-child(2) .decision-questions{
    min-height: calc(100% - 20px);
    overflow: auto;
    border: 1px solid var(--vlight-grey);
    background: var(--white);
}

.decision-wrapper .adjust-design:nth-child(2) .decision-questions .decision-question-left{
    border: none;
    background: none;
}


.decision-wrapper .adjust-design:nth-child(3) .decision-questions,
.decision-wrapper .adjust-design:nth-child(4) .decision-questions,
.decision-wrapper .adjust-design:nth-child(5) .decision-questions,
.decision-wrapper .adjust-design:nth-child(7) .decision-questions,
.decision-wrapper .adjust-design:nth-child(8) .decision-questions,
.decision-wrapper .adjust-design:nth-child(9) .decision-questions,
.decision-wrapper .adjust-design:nth-child(11) .decision-questions,
.decision-wrapper .adjust-design:nth-child(12) .decision-questions,
.decision-wrapper .adjust-design:nth-child(13) .decision-questions{
    margin-top: 0;
    margin-bottom: 0;
}

.decision-wrapper .adjust-design:nth-child(3){
    margin-top: 5px;
}

.decision-wrapper .adjust-design:nth-child(3),
.decision-wrapper .adjust-design:nth-child(4),
.decision-wrapper .adjust-design:nth-child(5){
    max-width: 300px;
    display: inline-block;
    width: 100%;
    border-radius: 5px;
    padding: 5px;
}

.decision-wrapper .adjust-design:nth-child(3) .decision-question-left,
.decision-wrapper .adjust-design:nth-child(4) .decision-question-left,
.decision-wrapper .adjust-design:nth-child(5) .decision-question-left{
    padding: 0;
    border: none;
}

.decision-wrapper .adjust-design:nth-child(3) .question_list,
.decision-wrapper .adjust-design:nth-child(4) .question_list,
.decision-wrapper .adjust-design:nth-child(5) .question_list{
    padding-left: 0;
}

.decision-wrapper .adjust-design:nth-child(3) .position,
.decision-wrapper .adjust-design:nth-child(4) .position,
.decision-wrapper .adjust-design:nth-child(5) .position{
    padding: 5px;
    border: 1px solid var(--vlight-grey);
    background: var(--white);
    border-radius: 5px;
}

.decision-wrapper .adjust-design:nth-child(3) .position_heading .text-3,
.decision-wrapper .adjust-design:nth-child(4) .position_heading .text-3,
.decision-wrapper .adjust-design:nth-child(5) .position_heading .text-3{
    display: block;
    padding: 10px 20px;
    background-color: var(--darkblue);
    border-radius: 5px;
    color: var(--white);
    text-align: center;
    font-size: 14px;
}

.decision-wrapper .adjust-design:nth-child(3) .position_answer,
.decision-wrapper .adjust-design:nth-child(4) .position_answer,
.decision-wrapper .adjust-design:nth-child(5) .position_answer{
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed var(--vlight-grey);
    background: var(--cream-white);
    text-align: left;
    border-radius: 5px;
    padding: 20px;
}

.decision-wrapper .adjust-design:nth-child(3) .text-3,
.decision-wrapper .adjust-design:nth-child(4) .text-3,
.decision-wrapper .adjust-design:nth-child(5) .text-3{
    font-size: 14px;
    line-height: 150%;
    color: var(--grey);
}

.decision-wrapper .adjust-design:nth-child(3) .position_answer.has_answer,
.decision-wrapper .adjust-design:nth-child(4) .position_answer.has_answer,
.decision-wrapper .adjust-design:nth-child(5) .position_answer.has_answer{
    background-color: var(--green);
}

.position_answer.has_answer li label{
    color: var(--white);
}

.decision-wrapper .adjust-design:nth-child(3) .position_answer.has_answer .text-3,
.decision-wrapper .adjust-design:nth-child(4) .position_answer.has_answer .text-3,
.decision-wrapper .adjust-design:nth-child(5) .position_answer.has_answer .text-3{
    color: var(--white);
}



.decision-wrapper .adjust-design:nth-child(3) .position_answer li,
.decision-wrapper .adjust-design:nth-child(4) .position_answer li,
.decision-wrapper .adjust-design:nth-child(5) .position_answer li{
    min-height: auto;
    display: block;
    border: none;
    background: transparent;
    text-align: center;
    border-radius: 0;
    padding: 0;
    margin-top: 0;
}

.decision-wrapper .adjust-design:nth-child(3) .position_answer li::before,
.decision-wrapper .adjust-design:nth-child(4) .position_answer li::before,
.decision-wrapper .adjust-design:nth-child(5) .position_answer li::before{
    display: none;
}

.decision-wrapper .adjust-design:nth-child(3) .position_answer li label,
.decision-wrapper .adjust-design:nth-child(4) .position_answer li label,
.decision-wrapper .adjust-design:nth-child(5) .position_answer li label{
    padding: 0;
    background: transparent;
    border: none;
}

.ondragitem{
    max-width: 300px;
    transition: 0.4s ease-in;
}

.adjust-design{
    overflow: hidden;
    max-height: 213px;
}


.decision-wrapper.with_question .adjust-design:nth-child(6),
.decision-wrapper.with_question .adjust-design:nth-child(10)
 {
    max-width: calc(100% - 300px);
    width: 100%;
    float: left;
    max-height: calc(100vh - 225px);
    height: 100%;
}

.decision-wrapper.with_question .adjust-design:nth-child(7),
.decision-wrapper.with_question .adjust-design:nth-child(8),
.decision-wrapper.with_question .adjust-design:nth-child(9),
.decision-wrapper.with_question .adjust-design:nth-child(11),
.decision-wrapper.with_question .adjust-design:nth-child(12),
.decision-wrapper.with_question .adjust-design:nth-child(13){
    max-width: 300px;
    display: inline-block;
    width: 100%;
    border-radius: 5px;
    padding: 5px;
}

.decision-wrapper.with_question .adjust-design:nth-child(7) .decision-question-left,
.decision-wrapper.with_question .adjust-design:nth-child(8) .decision-question-left,
.decision-wrapper.with_question .adjust-design:nth-child(9) .decision-question-left,
.decision-wrapper.with_question .adjust-design:nth-child(11) .decision-question-left,
.decision-wrapper.with_question .adjust-design:nth-child(12) .decision-question-left,
.decision-wrapper.with_question .adjust-design:nth-child(13) .decision-question-left{
    padding: 0;
    border: none;
}

.decision-wrapper.with_question .adjust-design:nth-child(7) .question_list,
.decision-wrapper.with_question .adjust-design:nth-child(8) .question_list,
.decision-wrapper.with_question .adjust-design:nth-child(9) .question_list,
.decision-wrapper.with_question .adjust-design:nth-child(11) .question_list,
.decision-wrapper.with_question .adjust-design:nth-child(12) .question_list,
.decision-wrapper.with_question .adjust-design:nth-child(13) .question_list{
    padding-left: 0;
}

.decision-wrapper.with_question .adjust-design:nth-child(7) .position,
.decision-wrapper.with_question .adjust-design:nth-child(8) .position,
.decision-wrapper.with_question .adjust-design:nth-child(9) .position,
.decision-wrapper.with_question .adjust-design:nth-child(11) .position,
.decision-wrapper.with_question .adjust-design:nth-child(12) .position,
.decision-wrapper.with_question .adjust-design:nth-child(13) .position{
    padding: 5px;
    border: 1px solid var(--vlight-grey);
    background: var(--white);
    border-radius: 5px;
}

.decision-wrapper.with_question .adjust-design:nth-child(7) .position_heading .text-3,
.decision-wrapper.with_question .adjust-design:nth-child(8) .position_heading .text-3,
.decision-wrapper.with_question .adjust-design:nth-child(9) .position_heading .text-3,
.decision-wrapper.with_question .adjust-design:nth-child(11) .position_heading .text-3,
.decision-wrapper.with_question .adjust-design:nth-child(12) .position_heading .text-3,
.decision-wrapper.with_question .adjust-design:nth-child(13) .position_heading .text-3{
    display: block;
    padding: 10px 20px;
    background-color: var(--darkblue);
    border-radius: 5px;
    color: var(--white);
    text-align: center;
    font-size: 14px;
}

.decision-wrapper.with_question .adjust-design:nth-child(7) .position_answer,
.decision-wrapper.with_question .adjust-design:nth-child(8) .position_answer,
.decision-wrapper.with_question .adjust-design:nth-child(9) .position_answer,
.decision-wrapper.with_question .adjust-design:nth-child(11) .position_answer,
.decision-wrapper.with_question .adjust-design:nth-child(12) .position_answer,
.decision-wrapper.with_question .adjust-design:nth-child(13) .position_answer{
    height: 125px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed var(--vlight-grey);
    background: var(--cream-white);
    text-align: left;
    border-radius: 5px;
    padding: 20px;
}

.decision-wrapper.with_question .adjust-design:nth-child(7) .text-3,
.decision-wrapper.with_question .adjust-design:nth-child(8) .text-3,
.decision-wrapper.with_question .adjust-design:nth-child(9) .text-3,
.decision-wrapper.with_question .adjust-design:nth-child(11) .text-3,
.decision-wrapper.with_question .adjust-design:nth-child(12) .text-3,
.decision-wrapper.with_question .adjust-design:nth-child(13) .text-3{
    font-size: 14px;
    line-height: 150%;
    color: var(--grey);
}

.decision-wrapper.with_question .adjust-design:nth-child(7) .position_answer.has_answer,
.decision-wrapper.with_question .adjust-design:nth-child(8) .position_answer.has_answer,
.decision-wrapper.with_question .adjust-design:nth-child(9) .position_answer.has_answer,
.decision-wrapper.with_question .adjust-design:nth-child(11) .position_answer.has_answer,
.decision-wrapper.with_question .adjust-design:nth-child(12) .position_answer.has_answer,
.decision-wrapper.with_question .adjust-design:nth-child(13) .position_answer.has_answer{
    background-color: var(--green);
}

.decision-wrapper.with_question .adjust-design:nth-child(7) .position_answer.has_answer .text-3,
.decision-wrapper.with_question .adjust-design:nth-child(8) .position_answer.has_answer .text-3,
.decision-wrapper.with_question .adjust-design:nth-child(9) .position_answer.has_answer .text-3,
.decision-wrapper.with_question .adjust-design:nth-child(11) .position_answer.has_answer .text-3,
.decision-wrapper.with_question .adjust-design:nth-child(12) .position_answer.has_answer .text-3,
.decision-wrapper.with_question .adjust-design:nth-child(13) .position_answer.has_answer .text-3{
    color: var(--white);
}



.decision-wrapper.with_question .adjust-design:nth-child(7) .position_answer li,
.decision-wrapper.with_question .adjust-design:nth-child(8) .position_answer li,
.decision-wrapper.with_question .adjust-design:nth-child(9) .position_answer li,
.decision-wrapper.with_question .adjust-design:nth-child(11) .position_answer li,
.decision-wrapper.with_question .adjust-design:nth-child(12) .position_answer li,
.decision-wrapper.with_question .adjust-design:nth-child(13) .position_answer li{
    min-height: auto;
    display: block;
    border: none;
    background: transparent;
    text-align: center;
    border-radius: 0;
    padding: 0;
    margin-top: 0;
}

.decision-wrapper.with_question .adjust-design:nth-child(7) .position_answer li::before,
.decision-wrapper.with_question .adjust-design:nth-child(8) .position_answer li::before,
.decision-wrapper.with_question .adjust-design:nth-child(9) .position_answer li::before,
.decision-wrapper.with_question .adjust-design:nth-child(11) .position_answer li::before,
.decision-wrapper.with_question .adjust-design:nth-child(12) .position_answer li::before,
.decision-wrapper.with_question .adjust-design:nth-child(13) .position_answer li::before{
    display: none;
}

.decision-wrapper.with_question .adjust-design:nth-child(7) .position_answer li label,
.decision-wrapper.with_question .adjust-design:nth-child(8) .position_answer li label,
.decision-wrapper.with_question .adjust-design:nth-child(9) .position_answer li label,
.decision-wrapper.with_question .adjust-design:nth-child(11) .position_answer li label,
.decision-wrapper.with_question .adjust-design:nth-child(12) .position_answer li label,
.decision-wrapper.with_question .adjust-design:nth-child(13) .position_answer li label{
    padding: 0;
    background: transparent;
    border: none;
}

@media (min-width: 1080px) and (max-width: 1600px) {
    /* .decision-wrapper{
        height: calc(100vh - 65px);
    } */

    .decision-wrapper .adjust-design:nth-child(3) .position_answer, 
    .decision-wrapper .adjust-design:nth-child(4) .position_answer, 
    .decision-wrapper .adjust-design:nth-child(5) .position_answer{
        height: 70px;
    }

    .decision-wrapper.with_question .adjust-design:nth-child(7) .position_answer, 
    .decision-wrapper.with_question .adjust-design:nth-child(8) .position_answer, 
    .decision-wrapper.with_question .adjust-design:nth-child(9) .position_answer, 
    .decision-wrapper.with_question .adjust-design:nth-child(11) .position_answer, 
    .decision-wrapper.with_question .adjust-design:nth-child(12) .position_answer, 
    .decision-wrapper.with_question .adjust-design:nth-child(13) .position_answer{
        height: 70px;
    }
        
}