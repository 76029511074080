.newsSection{
    position: relative;
    max-width: calc(100% - 240px);
    margin-left: auto;
    padding: 20px;
    min-height: calc(100vh - 62px);
    gap: 15px;
    flex-wrap: wrap;
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    justify-content: flex-start;
}

.product-top{
    background-size: cover !important;
}

.product{
    min-width: 310px;
    border: 1px solid var(--vlight-grey);
    max-width: 275px;
    max-height: 170px;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
}

span.notify{
    padding: 5px 10px;
    position: absolute;
    left: 10px;
    top: 10px;
    background: rgba(10, 37, 69, 0.3);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    font-size: 13px;
    color: var(--white);
}

span.dots{
    display: block;
    width: 2px;
    height: 2px;
    background: var(--black);
    border-radius: 50%;
    position: relative;
    cursor: pointer;
}

span.dots::after,span.dots::before{
    content: "";
    display: block;
    width: 2px;
    height: 2px;
    background: var(--black);
    border-radius: 50%;
    position: absolute;
    left: -5px;
}

span.dots::before{
    left: -10px;
}

.product .product-top{
    min-width: 100%;
    min-height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.product .product-bottom{
    padding: 15px;
    border-top: 1px solid var(--vlight-grey);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* inner side menu */

.newsExppore{
    position: relative;
    max-width: calc(100% - 240px);
    margin-left: auto;
    min-height: calc(100vh - 62px);
    background: var(--cream-white);
}

.innerSideMenu{
    position: fixed;
    max-width: 295px;
    width: 100%;
    min-height: calc(100vh - 62px);
    background: var(--white);
    border-right: 1px solid var(--vlight-grey);
}

.innerSideMenu ul{
    list-style: none;
    padding: 0;
    display: block;
    
}

.innerSideMenu ul li:first-child{
    border-top: 0;
}

.innerSideMenu ul li{
    display: block;
    border: 1px solid var(--vlight-grey);
    border-right: none;
    cursor: pointer;
}

.innerSideMenu ul li a{
    padding: 20px 15px;
    text-decoration: none;
    color: var(--black);
    font-size: 16px;
    display: flex;
    transition: 0.4s;
    position: relative;
    align-items: center;
    justify-content: space-between;
}

.innerSideMenu ul img{
    opacity: 0;
    transition: 0.4s;
}

.innerSideMenu ul li:hover img,.innerSideMenu ul li.active img{
    opacity: 1;
    transition: 0.4s;
}

.innerSideMenu ul li:hover a, .innerSideMenu ul li.active a{
    background: rgb(33 119 236 / 10%);
    color: var(--blue);
    transition: 0.2s;
}

.innerboard-wrapper{
    display: block;
    width: calc(100% - 295px);
    height: calc(100vh - 62px);
    margin-left: auto;
    position: relative;
    background: var(--cream-white);
    padding: 10px;
}

.innerboard{
    width: 100%;
    height: calc(100vh - 81px);
    background: var(--white);
    border-radius: 5px;
    padding: 20px;
    border: 1px solid var(--vlight-grey);
    display: none;
    overflow: auto;
}

.innerboard.active-tab{
    display: block;
}

ul.board-point{
    padding: 0;
    list-style: none;
}

ul.board-point li{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
}

ul.board-point li img{
    position: relative;
    top: 8px;
}

ul.board-point .heading-3{
    line-height: 150%;
    font-size: 20px;
    color: var(--black);    
    font-weight: bold;
}

.preLine{
    line-height: 23px;
    /* white-space: pre-wrap; */
}