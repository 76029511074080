
.datasetnav{
    padding: 15px;
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--vlight-grey);
    background: var(--white);
    z-index: 3000;
}

.datasetnav .data_filters{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}

.datasetnav .drop_selector select{
    padding: 8px 12px;
    border-radius: 5px;

    background-image: url('https://mastersunion.s3.ap-south-1.amazonaws.com/assets/imgV2/Pm_simulator_CaretDownBlack.png') !important;
    background-repeat: no-repeat !important;
    background-position-x: 90% !important;
    background-position-y: 50% !important;
    
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    -ms-appearance: none !important;
    -o-appearance: none !important;
    appearance: none !important;
    cursor: pointer;
    font-size: 12px;
}

.datasetnav .drop_selector.blue select{
    background-image: url('https://mastersunion.s3.ap-south-1.amazonaws.com/assets/imgV2/Pm_simulator_CaretDownWhite.png') !important;
    background: var(--blue);
    color: var(--white);
    border-color: var(--blue);
    min-width: 85px;
    color: var(--white);
}

.datasetnav .drop_selector.white select{
    border-color: var(--black);
    color: var(--black);
    min-width: 90px;
}

.datasetnav .drop_selector.year select{
    min-width: 115px;
}

.datasetboard_wrapper .innerboard{
    padding: 0;
    max-height: inherit;
    overflow: auto;
}

.datasetboard_wrapper .heading-3{
    font-family: 'inter-semibold';
    font-size: 18px;
    color: var(--black);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.datasetgraph_wrapper{
    padding: 20px;
}

.datasetgraph{
    width: 100%;
    min-height: 280px;
    border: 1px solid var(--vlight-grey);
    border-radius: 5px;
}

.datasettable_wrapper{
    padding: 20px;
    padding-top: 0;
}

.datasettable{
    width: 100%;
    min-height: 280px;
    border: 1px solid var(--vlight-grey);
    border-radius: 5px;
    max-width: 100%;
    overflow: auto;
}

.datasettable table{
    border-spacing: initial;
}

.datasettable table thead{
    background: var(--darkblue);
}

.datasettable table th{
    font-size: 14px;
    color: var(--white);
    text-align: left;
    padding: 15px;
    font-family: 'inter-regular';
    line-height: 120%;
    border-style: none;
    min-width: 110px;
    font-weight: 400;
}

.datasettable table td:first-child{
    border-left: 0;
}

.datasettable table td{
    font-size: 14px;
    color: var(--black);
    text-align: left;
    padding: 15px;
    font-family: 'inter-regular';
    line-height: 120%;
    border-style: none;
    min-width: 110px;
    font-weight: 400;   
    border-left: 1px solid var(--vlight-grey);
    border-bottom: 1px solid var(--vlight-grey);
}


.multi-select {
    width: 200px;
  }