.datePopup .new-height{
    min-height: 290px;
    transition: 0.4s ease-in-out;
}

.datePopup .popup-body{
    transition: 0.4s ease-in-out;
}

.react-daterange-picker{
    width: 100%;
}

.react-daterange-picker__inputGroup{
    flex-grow: initial;
    min-width: max-content;
}

.react-calendar{
    width: 100%;
    border-top: 0;
}

.react-daterange-picker__calendar{
    width: 100%;
}


.react-daterange-picker__wrapper{
    padding: 5px;
    gap: 5px;
}

.react-daterange-picker__button{
    position: absolute;
    right: 0;
    padding: 1px;
    padding-right: 5px;
}