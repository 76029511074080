.clickable{
    cursor: pointer;
}

.userdetail-popup .popup{
    min-width: 445px;
    max-width: 862px;
    padding: 0;
    border-radius: 5px;
}

.userdetail-popup-header{
    max-width: 100%;
    width: 100%;
    padding: 0;
    padding: 20px;
}

.rounds-count{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
}

.rounds-count .round-count-btn{
    font-size: 18px;
    line-height: 22px;
    font-family: 'inter-semibold';
    color: var(--darkgrey);
    cursor: pointer;
}

.text-left{
    text-align: left !important;
}

.text-right{
    text-align: right !important;
}

.rounds-count .round-count-btn.active{
    color: var(--black);
}

.round-detail-wrapper{
    padding: 20px;
}

.round-detail-wrapper .result_table.active{
    display: table;
}

.round-detail-wrapper .result_table{
    width: 100%;
    display: none;
}

.round-detail-wrapper.datasettable table td{
    border: 0;
    padding: 10px;
}